@import
'variables',
'mixins';

// -------------------------------------------------------------
//   Main layout
//   Defines basic main styles in layout
// -------------------------------------------------------------

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: $font-base;
}

body > svg {
  display: none;
}

body {
  background-color: #fff;
  color: $color-body;
  font-family: $ff-body;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  overflow-x: hidden;
  margin: 0;
  &.toolbar-fixed {
    .main-header {
      transform: translateY(39px);
    }
    &.toolbar-horizontal.toolbar-tray-open {
      .main-header {
        transform: translateY(79px);
      }
    }
  }
}
body.overlay-open {
  overflow: hidden;
}
.toolbar-oriented .toolbar-bar {
  z-index: 2200;
}
.section-wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  max-width: $xxl;
  @include mq-down($xxl) {
    max-width: $xl;
  }
  @include mq-down($xl) {
    max-width: $lg;
  }
  @include mq-down($lg) {
    max-width: $md;
  }
  @include mq-down($md) {
    max-width: $sm;
  }
  @include mq-down($sm) {
    max-width: 100%;
  }
  &.md {
    max-width: $md;
  }
  &.lg {
    max-width: $lg;
  }
  &.xl {
    max-width: $xl;
  }
}

.cols {
 display: grid;
 grid-gap: 20px;
 @include mq-up($lg) {
   grid-gap: 40px;
 }
 &.cols_2 {
   @include mq-up($md) {
     grid-template-columns: repeat(2, 1fr);
   }
 }
 &.cols_3 {
   @include mq-up($lg) {
     grid-template-columns: repeat(3, 1fr);
   }
 }
}

.iframe-embed {
  display: block;
  position: relative;
  padding: 0;
  width: 100%;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    padding-top: percentage(9 / 16);
  }

  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
  }
}

.bg {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.img-wrapper {
 position: relative;
 overflow: hidden;
 img {
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%,-50%);
   transform: translate(-50%,-50%);
   width: auto;
   min-width: 100%;
   height: auto;
   min-height: 100%;
 }
}

.path-sitemap, .path-search, .path-user {
 .main-wrapper {
   @extend .section-wrapper;
   padding-top: 60px;
   padding-bottom: 60px;
 }
}
.svg-list {
  display: none !important;
}
.img-animate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  mask: url(../images/image-anim.png);
  -webkit-mask: url(../images/image-anim.png);
  -webkit-mask-size: 4700% 100%;
  -webkit-mask-position: 0% 0;
  &.animated {
    animation: mask-play 1.4s steps(46) forwards;
  }
}
.bg {
  width: 100%;
  height: 938px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: rgb(102,223,217);
  background: -moz-linear-gradient(45deg, rgba(102,223,217,1) 0%, rgba(63,151,245,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(102,223,217,1) 0%,rgba(63,151,245,1) 100%);
  background: linear-gradient(45deg, rgba(102,223,217,1) 0%,rgba(63,151,245,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66dfd9', endColorstr='#3f97f5',GradientType=1 );
  overflow-x: hidden;
}
.bg-wave {
  width: 2768px;
  height: 900px;
  position: absolute;
  left: 0;
  bottom: 0;
  @include svgBackground('0 0 2768.01 900.003', '#fff', 'M0 900.003C99.043 895.04 239.551 868.688 389.009 779c351.911-211.177 370.019 37.402 595-11 174.337-37.507 455.647-804.499 802-540 0 0 214.823 218.002 729-228h253v900L0 900.003z');
  background-repeat: no-repeat;
  background-position: left;
  z-index: 2;

  @media screen and (max-width: 992px) {
    display: none;

  }
}
header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  .section-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .logo {
    padding-top: 30px;
    svg {
      path {
        fill: #fff !important;
      }
    }
  }
  .social {
    padding: 30px 0 0 0;
    a {
      display: inline-block;
      padding: 5px 7px;
      border: 2px solid rgba(255,255,255,.8);
      border-radius: 100%;
      line-height: 1;
      margin-left: 10px;
      svg {
        width: 14px;
        height: 16px;
        fill: rgba(255,255,255,.8);
      }
      &.tel {

        &.tel_desktop {
          border: 0;
          background-color: #fff;
          border-radius: 5px;
          color: $primary;
          padding: 10px;
          text-decoration: none;
          font-weight: 700;
          font-size: 1.4rem;
          display: none;

          @media screen and (min-width: 540px){
            display: inline-block;
          }
        }

        &.tel_mobile {
          display: none;

          @media screen and (max-width: 539px){
            display: inline-block;
          }
        }

      }
    }
  }
}
main {
  position: relative;
  z-index: 2;
  top: 100px;
}
.banner {
  text-align: center;
  h1 {
    font-size: 3.6rem;
    color: #fff;
    padding: 40px 0;
    margin: 0;
    line-height: 1.2;

    @media screen and (min-width: 768px) {
      font-size: 5rem;
    }
  }
}
.intro-form {
  .section-wrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
      flex-flow: row wrap;

    }

    .intro-list {
      width: 100%;
      @media screen and (min-width: 768px) {
        width: 48%;

      }
    }
    .intro-form-form {
      width: 100%;
      padding: 30px;
      background-color: #fff;
      -webkit-box-shadow: 0px 13px 32px 0px rgba(0,0,0,0.29);
      -moz-box-shadow: 0px 13px 32px 0px rgba(0,0,0,0.29);
      box-shadow: 0px 13px 32px 0px rgba(0,0,0,0.29);

      @media screen and (min-width: 768px) {
        width: 48%;
        -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.29);
        -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.29);
        box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.29);
      }
      h2 {
        text-align: center;
        font-size: 2rem;
      }
      form {
        input[type="text"] {
          width: 100%;
          background: #ececec;
          padding: 15px 10px;
          font-family: $ff-body;
          font-size: 1.6rem;
          color: $primary;
          border: 0;
          margin-bottom: 10px;
          outline: none;
        }
        textarea {
          width: 100%;
          background: #ececec;
          min-height: 100px;
          padding: 15px 10px;
          font-family: $ff-body;
          font-size: 1.6rem;
          color: $primary;
          border: 0;
          margin-bottom: 10px;
          outline: none;
        }
        .form-actions {
          text-align: center;
          button {
            background-color: $primary;
            color: #fff;
            font-size: 2rem;
            font-weight: 700;
            border: 0;
            padding: 10px 40px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
.intro-list {
  ul {
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 20px;
      color: #fff;
      padding-left: 80px;
      position: relative;
      min-height: 70px;
      font-size: 2rem;
      display: flex;
      align-items: center;
      svg {
        width: 60px;
        height: 60px;
        fill: $primary;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
.agence {
  padding: 50px 0 50px 0;
  position: relative;
  @media screen and (min-width: 768px) {
    padding: 100px 0 100px 0;
  }
  .section-wrapper {
    position: relative;
    z-index: 5;
  }
  h2 {
    text-align: center;
    color: $primary;
    font-size: 3rem;
    max-width: 400px;
    margin: 0rem auto 4rem;
    @media screen and (min-width: 768px) {
      margin: 6rem auto;
    }
  }
  .agence-boxes {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
     flex-flow: row wrap;
   }

   .box-left {
    width: 100%;
    padding: 20px;
    color: $primary;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.34);
    -moz-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.34);
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.34);

    @media screen and (min-width: 768px) {
      width: 48%;
    }
  }
  .box-right {
    width: 100%;
    margin-top: 4rem;
    color: transparentize($primary, .5);
    @media screen and (min-width: 768px) {
      width: 48%;
      margin-top: 0;
    }
  }
}
.wave-1 {
  position: absolute;
  z-index: 1;
  background-image: url(../images/wave-about-1.svg);
  width: 100%;
  height: 698px;
  top: 250px;
}
.wave-2 {
  position: absolute;
  z-index: 2;
  background-image: url(../images/wave-about-2.svg);
  width: 100%;
  height: 804px;
  top: 370px;
}
}
.realisations {
  padding-top: 50px;
  position: relative;
  z-index: 5;
  @media screen and (min-width: 768px) {
    padding-top: 100px;
  }
  .reas {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
      flex-flow: row wrap;
    }
  }
  h2 {
    text-align: center;
    color: $primary;
    font-size: 3rem;
    margin-bottom: 40px;
    margin-top: 0;
  }
  .rea {
    width: 100%;
    margin-top: 4rem;
    @media screen and (min-width: 768px) {
      width: 30%;
    }
    img {
      max-width: 100%;
    }
    h3 {
      text-align: center;
      margin-top: 0;
      a {
        text-decoration: none;
        color: $primary;
        font-size: 1.6rem;
      }
    }
  }
}
.team {
  padding-top: 50px;
  position: relative;
  z-index: 5;
  @media screen and (min-width: 768px) {
    padding-top: 100px;
  }
  h2 {
    text-align: center;
    color: $primary;
    font-size: 3rem;
    margin-bottom: 40px;
  }
  .team-wrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (min-width: 768px) {
      flex-flow: row wrap;
    }
    .team-item {
      width: 100%;
      @media screen and (min-width: 768px) {
        width: calc(50% - 2rem);
      }
      @media screen and (min-width: 992px) {
        width: calc(33% - 2rem);
      }
      img {
        max-width: 100%;
      }
      h3 {
        text-align: center;
        margin-bottom: 5px;
      }
      h4 {
        text-align: center;
        font-style: italic;
        margin-top: 0;
      }
    }
  }
}
footer {
  padding-top: 250px;
  padding-bottom: 40px;
  text-align: center;
  font-size: 2rem;
  position: relative;
  z-index: 5;

  .footer-content {
    z-index: 0;
  }
  a {
    color: $secondary;
    text-decoration: none;
  }
  .wave1 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(../images/wave-content-1.svg);
  }
  .wave2 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(../images/wave-content-2.svg);
  }
  .wave1 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(../images/wave-content-3.svg);
  }
}
