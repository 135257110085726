// -------------------------------------------------------------
//   Mixins
// -------------------------------------------------------------


// Media Queries
// Up
@mixin mq-up($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}
// Down
@mixin mq-down($breakpoint) {
  @media (max-width: ($breakpoint - 1)) {
    @content;
  }
}
// Between
@mixin mq-between($lower, $upper) {
    @media (min-width: $lower) and (max-width: ($upper - 1)) {
      @content;
    }
}

// Define vertical, horizontal, or both position
@mixin center($position) {
  position: absolute;

  @if $position == 'vertical' {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  @else if $position == 'horizontal' {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
  }
  @else if $position == 'both' {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

// Clearfix
@mixin clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Gradient
@mixin gradient($start-color, $end-color, $orientation: horizontal) {
  background: $start-color;

  @if $orientation == 'vertical' {
    background: -moz-linear-gradient(top, $start-color 0%, $end-color 100%);
    background: -webkit-linear-gradient(top, $start-color 0%,$end-color 100%);
    background: linear-gradient(to bottom, $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
  } @else if $orientation == 'horizontal' {
    background: -moz-linear-gradient(left, $start-color 0%, $end-color 100%);
    background: -webkit-linear-gradient(left, $start-color 0%,$end-color 100%);
    background: linear-gradient(to right, $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
  } @else if $orientation == 'diagonal' {
    background: -moz-linear-gradient(45deg, $start-color 0%, $end-color 100%);
    background: -webkit-linear-gradient(45deg, $start-color 0%,$end-color 100%);
    background: linear-gradient(45deg, $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
  } @else if $orientation == 'diagonal-inverse' {
    background: -moz-linear-gradient(-45deg, $start-color 0%, $end-color 100%);
    background: -webkit-linear-gradient(-45deg, $start-color 0%,$end-color 100%);
    background: linear-gradient(135deg, $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
  } @else {
    background: -moz-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
    background: -webkit-radial-gradient(center, ellipse cover, $start-color 0%,$end-color 100%);
    background: radial-gradient(ellipse at center, $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
  }
}

// Truncate Text
@mixin truncateText($overflow: ellipsis){
    overflow: hidden;
    white-space: nowrap;
    text-overflow: $overflow;
}

// Background svg
@mixin svgBackground($viewBox, $fill, $path) {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="'+$viewBox+'"><path fill="'+$fill+'" d="'+$path+'"/></svg>');
}
