@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

$primary:             #04205a;
$secondary: 			#13c0d7;

$color-body: #212529;
$font-base : 62.5%;

$xs: 0;
$sm: 510px;
$md: 768px;
$lg: 990px;
$xl: 1200px;
$xxl: 1440px;

$ff-body: 'Lato', sans-serif;


$font-family-sans-serif: 'Montserrat', sans-serif;